export default {
    "fr": {
        "main.sitename": "Wanaboat.fr",
        "main.back.to_webiste": "Retour au site",
        "main.quit.your.account": "Quitter votre compte",
        "main.currency": "Devise",
        "main.yes": "Oui",
        "main.no": "Non",
        "main.what": "Quoi",
        "main.close": "Fermer",
        "main.send": "Envoyer",
        "main.delete": "Supprimer",
        "main.see": "Voir",
        "main.title": "Titre",
        "main.update": "Modifier",
        "main.name": "Nom",
        "main.phone": "Tél",
        "main.back": "Retour",
        "main.back.to.homepage": "Retour à l'accueil",
        "main.for.sale": "à vendre",
        "main.sails": "Voiles",
        "main.for": "pour",
        "main.no.ads.message": "Malheureusement, il n'y a pas d'annonce pour vos critères Capitaine, pouvez-vous chercher autre chose ?",
        "main.no.ads.button.label": "Ou publiez la vôtre",
        "main.cta.ad.contact": "Contactez moi",
        "main.harbour.office": "Capitainerie",
        "main.used.boats": 'Bateaux d\'occasion',
        "main.used.boats": 'occasion',
        "main.flag.france": "🇫🇷",
        "main.flag.england": "🇬🇧",
        "main.favorites": "Favoris",
        "main.guide.of": "Guide du {name}",
        "main.used": "occasion",
        "main.famous.sailor.email": "eric@tabarly.fr",
        "main.sell": "Vend",
        "main.rent": "Loue",
        "main.search": "Cherche",
        "main.research": "Chercher",
        "main.validate": "Valider",
        "main.save": "Sauvegarder",
        "main.cancel": "Annuler",
        "main.ads": "Annonces",
        "main.your.ads": "Vos annonces",
        "main.models": "Modèles, classes",
        "main.brands": "Marques et chantiers",
        "main.univers": "Univers",
        "main.login": "connectez-vous",


        "main.brand.moto": "⚓ Wanaboat.fr, les petites annonces nautiques avec politesse et sans arnaque",
        "main.brand.moto.2": "Votre bateau mérite un meilleur coin",

        "univers.most.expensive": "Les {name} les moins chers",
        "univers.cheapest": "Les {name} les plus chers",

        "about.hi.captain": "Bonjour Capitaine !",
        "about.what.do.you.want": "Que cherchez-vous ?",
        "about.publish.an.ad": "Publier une annonce",
        "about.access.to.your.account": "Accéder à votre compte",
        "about.get.more.infos": "En savoir plus sur Wanaboat.fr",
        "about.contact.us": "Me contacter",
        "about.go.to.pro.list": "Accéder à la liste des vendeurs pro",
        "about.read.our.blog": "Lire notre blog",
        "about.page.contact.notice": "Vous souhaitez me contacter directement, appelez-moi à la {email}, je réponds toujours (plutôt rapidement) !",
        "about.alloffame.title": "Le panthéon des utilisateurs Wanaboat",
        "about.alloffame.notice": "Voici les noms de celles et ceux que je souhaite remercier. Wanaboat un peu, (voire beaucoup pour certains) grâce à eux.",
        "about.support": "Soutenir Wanaboat",


        "home.welcome": "Bienvenue sur Wanaboat",
        "home.declarative": "Le meilleur site pour acheter et vendre un bateau",

        "nav.nav": "Naviguez !",
        "nav.back": "Retour",
        "nav.publish": "Publier votre annonce",
        "nav.userAccount": "Votre compte",
        "nav.about.item": "À propos de Wanaboat.fr",
        "nav.connection.notice": "Pour utiliser toutes les possibilités de Wanaboat",

        "breadcrumb.used.boats": "Bateaux d'occasion",

        "header.h1": "achat/vente d'occasion, annonces et prix",
        "header.h2.barely-docked": "À peine à quai, ",
        "header.h2.last-univers-ads": "les dernières annonces {name} au meilleur prix",
        "header.h2.for-you": "pour vous sur Wanaboat",

        "models.list.title": "Tous les modèles de {universName} à vendre en occasion",
        "models.list.title.generic": "Les modèles en occasion",

        "brand.model.made.by": "Les modèles construits par {name}",

        "brands.specialized.boats.builder": "Chantiers navals spécialisés {universName}",

        "cta.publish.with.univers": "Publiez une annonce gratuite",
        "cta.see.all.ads": "Voir toutes les annonces ↓",
        "cta.see.all.brands": "Voir tous les chantiers",
        "cta.see.all.models": "Voir tous les modèles",
        "cta.see.less.models": "Voir moins",
        "cta.publish.in.three.minutes": "Publier votre annonce en 3 min",


        "ad.price": "Prix",
        "ad.area": "Localisation",
        "ad.builder": "Chantiers",
        "ad.sail.maker": "Voilerie",
        "ad.brand": "Marque",
        "ad.brands": "Marques",
        "ad.year": "Année",
        "ad.reply.average.delay": "répond en moyenne en",
        "ad.message.send.sucess.notice": "Votre message a bien été passé à la VHF Capitaine. Surveillez vos emails (même les spams) dans les prochains jours.",


        "filters.filters": "Filtrer",
        "filters.all": "Tous",
        "filters.type": "Type",
        "filters.sorting": "Classement",
        "filters.categories": "Catégories",
        "filters.most.recent": "Les plus récentes",
        "filters.cheapest": "Les moins chères",
        "filters.most.expensive": "Les plus chères",
        "filters.all.together": "Tout à la fois",
        "filters.full.boats.only": "Bateaux seulement",
        "filters.sails.only": "Voiles",
        "filters.parts.only": "Pièces / Accastillage",
        "filters.trolleys.only": "Remorques",
        "filters.countries": "Pays",
        "filters.type.for.sale": "À vendre",
        "filters.type.for.rent": "À louer",
        "filters.type.looking.for": "Cherche",

        "ad.pictures": "{count} photo",
        "ad.pictures.plural": "{count} photos",
        "ad.gallery.close.the.diaporama": "Fermer le diaporama",
        "ad.maker": "Constructeur",
        "ad.department": "Département",
        "ad.region": "Région",
        "ad.country": "Pays",
        "ad.direct.link": "Lien direct",
        "ad.share": "Partager",
        "ad.send": "Envoyer",
        "ad.localisation": "Localisation",
        "ad.title.details": "Détails",
        "ad.obsolete.answer": "Cette annonce n'est plus d'actualité ? Le bien est vendu et vous le savez ? Dites le moi et je  mènerai l'enquête : vhf@wanaboat.fr Merci ! 👍 Objectif : 100% d'annonces valides, aidez-moi !",
        "ad.copy.button": "Copier",
        "ad.copy.button.ok": "C'est bon!",
        "ad.related.ads.list.title": "Vous pouvez peut-être jeter également un œil à celles-ci",
        "ad.sold.by": "Vendu par",
        "ad.record.favorite": "Mettre en favori",


        "seller.contact.form.name": "Nom / pseudo",
        "seller.contact.form.phone": "Téléphone",
        "seller.contact.form.send.message": "Envoyez votre demande",
        "seller.contact.form.fill.first": "Remplissez le formulaire d'abord",
        "seller.contact.form.back.to.ad": "Retour à l'annonce",
        "seller.contact.form.first.message.disclaimer": "C'est votre premier message ici Capitaine, tout d'abord, bienvenue à bord. Je vais créer un compte pour vous et en même temps que votre message sera envoyé au vendeur, je vous envoie l'accès à la messagerie par email. Il me faut donc absolument une adresse valide 👍",
        "seller.contact.form.privacy.disclaimer": "À la capitainerie Wanaboat, on protège les vendeurs des démarchages intempestifs. Ainsi, il faut montrer patte blanche moussaillon, c'est pour la bonne cause !",
        "seller.contact.form.filling.disclaimer": "Tous les champs sont obligatoires. Pas d'inquiétude, tout est sécurisé, vos coordonnées seront un secret mieux gardé que la position du trésor de Rackham le Rouge. Nous les transmettons au vendeur pour qu'il vous recontacte.",
        "seller.contact.form.language.fr.notice": "Ce vendeur utilise principalement la langue française",
        "seller.contact.form.language.en.notice": "Ce vendeur utilise principalement la langue anglaise",
        "seller.contact.form.notice.contact.as": "Vous allez contacter le vendeur en tant que",


        "countries.all": "all",
        "countries.great.britain": "Grand Bretagne",
        "countries.france": "France",
        "countries.germany": "Allemagne",
        "countries.belgium": "Belgique",
        "countries.swiss": "Suisse",
        "countries.italy": "Italie",
        "countries.spain": "Espagne",
        "countries.switzerland": "Suisse",
        "countries.netherlands": "Pays-Bas",
        "countries.usa": "USA",
        "countries.australia": "Australie",
        "countries.new.zealand": "Nouvelle Zélande",
        "countries.portugal": "Portugal",

        "model.subscribe.form.title": "Recevez chaque nouvelle annonce {modelName}",
        "model.subscribe.form.validate": "Valider",
        "model.subscribe.form.message": "Pour que la bonne annonce ne vous passe pas sous le nez",
        "model.subscribe.form.confirmation": "C'est noté capitaine, les prochaines annonces {modelName} arriveront dans votre boîte mail dès la mise en ligne !",
        "model.subscribe.form.interested.by": "Intéressé par le {modelName}",
        "model.subscribe.button.label": "Abonnez-vous aux annonces",

        "model.price.table.title": "Liste des prix des {modelName}",
        "model.stats.title": "{modelName} en quelques chiffres",
        "model.stats.other.brands": "Marques diverses",
        "model.charts.price.brand.title": "Prix moyen {modelName} selon le constructeur",
        "model.charts.price.year.title": "Prix moyen {modelName} selon l'année de production",
        "model.presentation.title": "Présentation du {modelName} ",
        "brand.presentation.title": "Présentation de {modelName} ",
        "model.see.europe.map.of.model": "Découvrer la carte {modelName}",

        "model.table.ad.name": "Titre de l'annonce",
        "model.stats.average.price": "Prix moyen",
        "model.stats.median.price": "Prix median",
        "model.stats.sold.boats": "Bateaux vendus en 1 an",
        "model.stats.min.price": "Prix mini",
        "model.stats.max.price": "Prix maxi",
        "model.stats.available.boats": "Bateaux disponibles",
        "model.stats.launch.year": "Année de lancement",
        "model.stats.sail.area": "Surface de voile",
        "model.stats.weight": "Poids",
        "model.stats.designer": "Architecte",
        "model.stats.crew": "Équipage",
        "model.stats.notice": "Ces statistiques prennent en compte toutes les annonces {modelName} qui ont été publiées, certaines ne sont plus en ligne aujourd'hui",
        "model.versions.title": "La série {modelName} a {versionsCount} versions différentes",

        "model.export.tab.ads": "Liste des annonces",
        "model.export.tab.map": "Carte des annonces",
        "model.export.tab.market": "Marché du {modelName}",

        "model.no.ad.notice.title": "Il n'y a pas d'annonce {name} actduellement",
        "model.no.ad.notice": "Abonnez-vous à cette série (juste au dessus) pour ne pas rater la prochaine opportunité",

        "brand.presentation.title": "Présentation de {modelName}",
        "brand.main.title": "Les marques principales de la catégorie",

        "account.validation.waiting.message": "Validation de votre compte en cours.",
        "account.validation.its.ok": "C'est fait Capitaine !",
        "account.validation.notice.1": "Votre compte est validé et votre annonce sera en ligne dans quelques instants. Cette étape servait à ce qu'on soit certains que vous recevez bien nos emails car quand un acheteur vous contactera, vous serez alerté par email.",
        "account.validation.notice.2": "Pour être parfaitement sûr de bien recevoir nos emails, vous pouvez même ajouter \"vhf@wanaboat.fr\" à votre carnet d'adresse.",
        "account.validation.back.to.homepage": "Retour à la page d'accueil",

        "account.login.notice": "Bonjour Capitaine, afin de vous identifier, je vais vous envoyer un message par email. Ce message contient un lien sur lequel il suffit de cliquer. Simple, non ?",
        "account.login.your.email": "Votre email",
        "account.login.send.link": "Recevoir le lien d'identification",
        "account.login.message.sent.notice": "Le lien d’identification vient de vous être envoyé par email Captain, il doit déjà être dans votre boîte de réception. N’hésitez-pas à la mettre en favori.",
        "account.login.verification": "Vérification de votre carnet de",


        "account.nav.welcome": "Votre compte",
        "account.nav.discussions": "Discussions",
        "account.nav.classifieds": "Annonces",
        "account.nav.alerts": "Vos alertes",
        "account.nav.setttings.your.contact": "Vos contacts",
        "account.nav.setttings.email.update.notice": "Pour mettre à jour votre adresse email, faites la demande à vhf@wanaboat.fr",
        "account.nav.settings": "Profil et réglages",

        "account.welcome.message": "Bonjour, bienvenue dans vos quartiers. Ci dessous, de quoi gérer votre compte et vos annonces. Faites comme chez vous Capitaine.",
        "account.message.you.have.not.been.contacted": "Vous n'avez pas de conversation en cours actuellement.",
        "account.message.from": "De",
        "account.message.to": "À",
        "account.message.new": "Nouveau",
        "account.message.for": "pour",
        "account.message.velocity.notice": "Capitaine, il faut que vous sachiez, le temps que vous mettez à répondre à vos messages est mesuré et utilisé dans le classement des annonces. Ça récompense ceux qui intéragissent vite avec les acheteurs, c'est pour la bonne cause ;)",
        "account.do.you.want.to.share.contact": "Vous souhaitez échanger vos coordonnées téléphoniques et électroniques avec l'acheteur ?",
        "account.archived.discussion.notice": "L'annonce relative à cette discussion étant supprimée, elle est en lecture seule.",
        "account.see.archived.discussions": "Voir les discussions archivées",

        "account.join.file.join.and.upload": "Joindre des photos",

        "account.settings.log.out": "Déconnexion",
        "account.settings.log.out.notice": "À la capitainerie Wanaboat.fr, vous êtes toujours le bienvenu. Ainsi, la connexion est permanente tant que vous ne changez pas d'ordinateur ou de téléphone. Ce faisant, si vous avez besoin de prendre le large quelques temps, c'est ici :",
        "account.settings.newsletter.notice": "Souhaitez-vous recevoir notre lettre d'information (de 2 à 6 par an suivant la capacité des matelots de la capitainerie à en rédiger quelques unes)",
        "account.settings.newsletter.receive": "Je m'abonne à la newsletter",
        "account.settings.final.deletion": "Suppression définitive",
        "account.settings.final.deletion.notice": "Si vous souhaitez supprimer définitivement votre compte, écrivez au matelot de quart à vhf@wanaboat.fr",
        "account.settings.preferred.language": "Votre langage préféré",
        "account.settings.preferred.language.notice": "Vous préférez que nous vous parlions en :",

        "account.settings.short.presentation": "Courte présentation",
        "account.settings.birth.date": "Date de naissance",
        "account.settings.your.location": "Votre localisation",

        "account.settings.pict.uploader.label": "Choisir une photo",
        "account.settings.pict.rotate.notice": "Cliquez sur l'image pour la redimensionner sur votre visage",

        "account.settings.pict.crop.notice": "Cliquez sur l'image pour la redimensionner sur votre visage",


        "account.message.phone": "Phone",
        "account.do.you.want.to.share.contact": "Voulez-vous échanger vos coordonnées avec cet acheteur ?",
        "account.archived.discussion": "Discussions archivées",
        "account.ad.being.discussed": "L'annonce dont on papote",
        "account.buyer.contact.card.seller": "Coordonnées du vendeur",
        "account.buyer.contact.card.buyer": "Coordonnées de l'acheteur",
        "account.buyer.contact.notice.1": "Si vous avez confiance en cet interlocuteur, vous pouvez ",
        "account.buyer.contact.button": "partager vos coordonnées ",
        "account.buyer.contact.notice.2": "Attention, vos discussions ici sont protégées avec un système anti-arnaqueurs. À partir du moment où vous passez par email ou téléphone, je ne vous protège plus de rien Capitaine. Soyez bien vigilant.",

        "account.ad.no.ad.online.notice": "Vous n'avez actuellement pas d'annonce publiée sur Wanaboat",

        "account.message.is.read": "Lu",
        "account.alerts.title": "Voici la liste de vos souscriptions à des modèles",
        "account.alerts.notice": "Quand une nouvelle annonce est publiée vous recevez l'info par email dès que l'annonce est en ligne.",
        "account.alerts.no.alert.notice": "Vous n'avez pas encore d'alerte. Pour en créer une, sur la page d'un modèle mettez votre email dans la case «recevoir les alertes»",
        "account.alert.type.all": "Toutes",
        "account.alert.type.boat": "Bateaux seulement",
        "account.alert.type.other": "Pièces, voiles et remorques",

        "login.form.name.and.contact": "Nom ou raison social",

        "form.international.number": "Numéro international",
        "form.international.placeholder": "Numéro sans indicatif",
        "before.you.start": "Avant de commencer",
        "form.lets.go.captain": "C'est parti mon Capitaine",
        "form.step1.title": "Vous êtes ici pour...",

        "form.step1.sell": "Vendre",
        "form.step1.rent": "Louer",
        "form.step1.search": "Chercher",

        "form.step1.complete.boat": "Un bateau complet",
        "form.step1.complete.sails": "Des voiles",
        "form.step1.complete.parts": "Des pièces de l'accastillage",
        "form.step1.complete.trolley": "Une remorque",

        "form.step2.notice1": "Nous allons prendre la saisie pour",
        "form.step2.notice2": "Ça vous prendra 3 minutes environ. Vous devez avoir un petit descriptif",
        "form.step2.notice3": "de ce que vous souhaitez vendre et quelques photos",

        "form.step2.your.boat": "votre bateau",
        "form.step2.your.sails": "vos voiles",
        "form.step2.your.parts": "votre matériel",
        "form.step2.your.trolley": "votre remorque",
        "form.step2.its.a": "C'est un",
        "form.step2.of": "Pour un",
        "form.step2.for.a": "Pour un",

        "form.step2.catamaran": "Catamaran",
        "form.step2.sailing.dinghy": "Dériveur léger",
        "form.step2.dayboat": "Dayboat",
        "form.step2.offshore.racing.boat": "Bateau de course au large",
        "form.step2.cruising.sailboat": "Bateau de croisière",

        "form.step3.which.model": "De quel modèle/série ?",
        "form.step3.which.for.which.model": "Pour quel modèle/série ?",
        "form.step3.notice": "Si vous ne le trouvez pas, choisissez \"Divers\" et nous créerons le modèle par la suite.",
        "form.step3.which.version": "De quelle version ?",

        "form.step35.build.when": "Fabriqué en quelle année",
        "form.step35.i.dont.knwow": "Je sais plus",
        "form.step35.it.comes.back.to.me": "Ah, si, ça me revient",

        "form.step35.built.by.which.shipyard": "Fabriqué par quel chantier ?",
        "form.step35.from.which.sailmaker": "De quelle voilerie ?",
        "form.step35.which.maker": "De quelle marque ?",
        "form.step35.brand.notice": "Si elle n'existe pas, choisissez \"Autre marque\".",
        "form.step.35.other.brand": "Autre marque",

        "form.step4.where.is": "Où se trouve",
        "form.step4.the.boat": "le bateau",
        "form.step4.the.sails": "le(s) voile(s)",
        "form.step4.the.parts": "le(s) pièce(s)",
        "form.step4.the.trolley": "la remorque",
        "form.step4.is.it.here": "C'est bien là ?",
        "form.step4.notice": "Tapez la ville, et éventuellement le département ou le pays s'il est hors d'Europe.",
        "form.step4.confirm.this.location": "Valider la localisation",
        "form.step.4.city.department.country": "Ville, Région, Pays",

        "form.step5.title.of.your.ad": "Titre de votre annonce",
        "form.step5.notice": "Voici notre proposition de titre, vous pouvez la modifier",
        "form.step5.validate.this.title": "Valider ce titre",
        "form.step5.in": "en",
        "form.step5.parts.of": "Pièce de",

        "form.step6.describe.precisely.what.you.sell": "Descrivez le plus précisement ce que vous vendez",
        "form.step6.validate.this.description": "Valider la description",
        "form.step6.wysiwyg.boat.placeholder": "Décrivez le bateau que vous vendez le plus précisément possible",
        "form.step6.wysiwyg.other.placeholder": "Descrivez le matériel que vous vendez le plus précisément possible",

        "form.step7.pictures": "Photos",
        "form.step7.of.the.boat": "du bateau",
        "form.step7.of.the.sails": "des voiles",
        "form.step7.of.the.parts": "des pièces",
        "form.step7.of.the.trolley": "de la remorque",
        "form.step7.select.the.pictures": "Choisissez les photos",
        "form.step7.as.many.pictures": "Autant que vous voulez, ici c'est pas LeBonCoin, quand on aime, on ne compte pas ;). Et pour info, les photos seront recadrées au format carré pour une meilleure homogénéité.",
        "form.step7.continue.without.pictures": "Continuer sans photo (c'est dommage)",
        "form.step7.notice": "Cliquez sur «Choisir» pour sélectionner la photo de couverture de votre annonce.",
        "form.step7.primary": "Principale",
        "form.step7.choose": "Choisir",
        "form.step7.confirm.picture": "Valider cette photo",
        "form.step7.confirm.pictures": "Valider ces {count} photos",

        "form.step8.price.label": "Et pour terminer, le prix",
        "form.step8.notice": "Le prix est obligatoire, vous pouvez mentionner «à discuter» dans votre annonce, mais le prix demandé est nécessaire.",
        "form.step8.notice.2": "Vous pouvez saisir le prix en Euro, Dollar ou Livres Sterlings",

        "form.step9.is.it.you": "Pouvez-vous compléter les informations manquantes ou rectifier les informations erronées ?",
        "form.step9.fill.your.infos.first": "Remplissez d'abord vos informations s'il vous plaît",
        "form.step9.yes.its.ok": "Oui, c'est bon",
        "form.step9.i.valid": "C'est tout bon",
        "form.step9.name.and.contact": "Nom, raison sociale & contacts",
        "form.step9.phone.and.prefix": "Téléphone, indicatif + numéro",
        "form.step9.email.address": "Votre adresse email",
        "form.step9.email.address.confirmation": "Confirmation de votre email",
        "form.step9.notice": "Vos coordonnées ne seront pas visibles au public. Nous vous enverrons les coordonnées des parties intéressées par courrier électronique. Vous pouvez les indiquer sans crainte.",

        "form.step.10.fully.fill.notice": "Complétez le formulaire intégralement avant de pouvoir poursuivre",
        "form.step10.confirm.ad.and.finalize": "Valider l'annonce et finaliser",

        "form.step11.title": "C'est presque fini mon Capitaine",
        "form.step11.what.did.you.think": "Qu'avez-vous pensé du formulaire",
        "form.step11.meh": "Bof",
        "form.step11.correct": "Correct",
        "form.step11.its.sucks": "Nul",
        "form.step11.its.awful": "Horrible",
        "form.step11.notice": "Il est très important pour moi de savoir comment améliorer le service au maximum !",
        "form.step11.publish.your.ad": "Publier votre annonce",
        "form.step.11.what.could.be.improved": "Qu'est-ce qui pourrait être amélioré pour le rendre parfait ?",
        "form.step.11.placeholder": "Parce que nous voulons vraiment qu'il soit parfait, pour vous satisfaire à 100% la prochaine fois.",
        "form.step.11.five.stare.rate.comment": "Cela nous fait très plaisir ! Bonne chance pour la vente, Capitaine !",

        "form.step.11.congratulations.captain": "Félicitation Capitaine",
        "form.step.11.notice.2": "Votre annonce sera en ligne dans 5 à 10 minutes après quelques vérifications finales. Il faut juste que vous validiez vos coordonnées, c'est important pour que je puisse bien communiquer avec vous.",
        "form.step.11.notice.3": "Vous devez déjà avoir un e-mail dans votre boîte de réception avec un lien sur lequel vous devez cliquer pour activer l'annonce. Elle sera en ligne dès que vous aurez effectué cette dernière manipulation.",
        "form.step.11.notice.4": "Youpi, c'est fini. Vous recevrez les demandes d'infos des acheteurs par email, surveillez bien votre boîte mail Capitaine",
        "form.step.11.notice.5": "Je vous enverrai également un mail avec quelques conseils pour rendre votre annonce bien visible",
        "form.step.11.go.back.to.homepage": "Retour à la page d'accueil",

        "form.update.translation.notice": "Votre annonce a été automatiquement traduite lors de sa création. C'est pour vous aider à toucher un maximum d'acheteurs potentiels. Vous pouvez ici apporter des modifications à la traduction du titre et du descriptif pour améliorer sa présentation sur le site international de Wanaboat",

        "donation.button.label": "Soutenir Wanaboat",
        "donation.title": "Capitaine, seriez-vous d'accord pour soutenir le service",
        "donation.appreciate": "Vous semblez avoir apprécié le service, voulez-vous le soutenir",

        "donation.notice.1": "Wanaboat, c'est pas Google ou LeBonCoin, c'est un projet de passionné, fait pour rendre service aux voileux. Il est sans pub et gratuit pour les utilisateurs mais il y a quelques coûts pour faire tourner la machine et j’y consacre un temps bénévole pas négligeable pour que le site soit performant, agréable et sûr.",
        "donation.notice.2": "Faire tourner la machine coûte une centaine d'Euro par mois (serveur + système d'envoi des emails principalement). Je paie tout de ma poche... moins vos dons. Je vous demande donc un coup de pouce pour partager la note si vous le souhaitez et selon vos possibilités financières.",
        "donation.notice.3": "Capitaine, vous venez de publier une annonce sur Wanaboat et vous avez apprécié l'expérience. Tout d'abord, merci pour les 5 ⭐ ça fait plaisir et ça motive. J'ai une demande un peu solennelle à vous faire",
        "donation.notice.4": "souhaitez-vous soutenir le service",

        "donation.will.be.used": "Votre don servira à :",
        "donation.will.pay.charges": "payer les charges (hébergement, routage email, anti spam & arnaques)",
        "donation.will.upgrade.service": "améliorer et péréniser le service.",
        "donation.will.keep.service.without.advert": "garder un site sans publicité et sans vente de données",
        "donation.have.a.drink": "...et boire un verre à l'occasion",

        "donation.will.upgrade.service": "améliorer le service, développer de nouvelle fonctionnalités",
        "donation.have.a.drink": "...et boire une bière \"à l'occasion\"",
        "donation.of.the.price": "du prix",


        "donation.of.your": "de votre",
        "donation.refuse.button.label": "Non, la prochaine fois matelot !",
        "donation.price.1.notice": "1 bière et des chips 🍺",
        "donation.price.2.notice": "1 bière pour moi et ma copine 🍻",
        "donation.price.3.notice": "1 tournée pour moi et mes potes 🍻 🍻",
        "donation.price.4.notice": "Une nouvelle tireuse pour le clubhouse 🍻 🍻 🍻",

        "donation.price.5.notice": "Un café ☕",
        "donation.price.6.notice": "Un bière 🍺",
        "donation.price.7.notice": "Une bière et des chips 🍺🍟",
        "donation.price.8.notice": "Une tournée 🍻 🍻 🍻",

        "donation.custom.notice": "Aucun de ces montants ne vous correspond, choisissez le vôtre",
        "donation.custom.button.label": "Merci, c'est top",
        "donation.custom.field.placeholder": "À votre bon cœur",

        "donation.thankyou.title": "Ça me fait tout chaud au ❤️ Capitaine !",
        "donation.thankyou.notice": "Votre générosité me motive à continuer à faire mon maximum vous proposer un Wanaboat toujours meilleur !",
        "donation.thankyou.notice.2": "Votre don offre les moyens à Wanaboat de continuer à offrir un service sympa, efficace et sans pub, merci.",
        "donation.thankyou.go.home.button.label": "Retour à l'accueil sous mes applaudissements",

        "ad.preview.title1": "Bonjour Capitaine,",
        "ad.preview.title2": "On a repéré votre magnifique {modelName} à vendre, voici ce qu'on vous propose :",
        "ad.preview.notice1": "Wanaboat.fr est un site gratuit et spécialisé dans les petites annonces nautiques depuis 2007. Le site est simple et super sécurisé.",
        "ad.preview.notice2": "Beaucoup de nos visiteurs s'intéressent au {modelName} actuellement.",
        "ad.preview.notice3": "Une question avant de vous lancer ? Contactez-moi à vhf@wanaboat.fr",
        "ad.preview.notice4": "Vous avez juste 3 infos à remplir, ce sera fait dans 1 minute",
        "ad.preview.notice5": "Votre annonce sera en ligne dans quelques instants après quelques dernières vérifications.",

        "ad.preview.arg1": "On met l'annonce pour vous sur Wanaboat",
        "ad.preview.arg2": "C'est gratuit et sans engagement particulier",
        "ad.preview.arg3": "Vous avez juste à cliquer sur «oui» et remplir 2 infos",

        "ad.preview.button.yes.label": "Oui, on y va",
        "ad.preview.button.no.label": "Non, ça ne m'intéresse pas",
        "ad.preview.button.back.to.homepage": "Retour à l'accueil",
        "ad.preview.great.lets.go": "Super, allons-y",

        "ad.preview.its.ok": "C'est fait",

        "favorites.panel.title": "Vos annonces préférées",
        "user.creation.short.form.label": "Votre email",
        "user.creation.short.form.email.notice.1": "J'ai besoin de votre email pour enregistrer vos favoris Capitaine ",
        "user.creation.short.form.email.notice.2": "Je le partage à personne pour sûr",

        "delete.wizard.title": "Suppression de votre annonce",
        "delete.wizard.notice.1": "Une dernière question importante pour les statistiques du site mon Capitaine",
        "delete.wizard.notice.2": "Wanaboat vous a-t-il été utile",

    }
}